import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import 'focus-visible/dist/focus-visible.js'
import SiteHeadMeta from './site-head-meta'
import Header from './header'
import Footer from './footer'
import ScrollToTopBtn from './scroll-to-top-btn'

import './../styles/styles.scss'

export default function Layout({
  children,
  pageName,
  documentTitlePrefix = '',
  isIndexPage = false,
  showScrollToTopBtn = true,
  sectionObserved,
}) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <StyledComponent id="top" className={`site-wrapper page--${pageName}`}>
      <SiteHeadMeta documentTitlePrefix={documentTitlePrefix} isIndexPage={isIndexPage} />
      <Header pageName={pageName} isIndexPage={isIndexPage} sectionObserved={sectionObserved} />
      <main className={`site-main site-main--${pageName}`}>
        {children}
        {showScrollToTopBtn && <ScrollToTopBtn />}
      </main>
      <Footer pageName={pageName} isIndexPage={isIndexPage} />
    </StyledComponent>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledComponent = styled.div`
  main {
    /* min-height: calc(100vh - 17.6rem);

    @media (min-width: 570px) {
      min-height: calc(100vh - 14.6rem);
    }

    @media (min-width: 1200px) {
      min-height: calc(100vh - 9.6rem);
    } */
  }
`
