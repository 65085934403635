import React, { useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import { RemoveScrollBar } from 'react-remove-scroll-bar'
import kebabCase from 'lodash/kebabCase'
import Hamburger from './hamburger'
import MenuItem from './menu-item'
import NavMenuList from './nav-menu-list'
import Container from './container'
import {
  SLIDE_DOWN_MOBILE_NAV_DURATION_MS,
  LINK_TYPE_DOWNLOAD_FILE,
  LINK_TYPE_EXTERNAL_URL,
  LINK_TYPE_SCROLL_TO_ANCHOR,
  LINK_TYPE_EMAIL_LINK,
  LINK_TYPE_TELEPHONE_LINK,
  LINK_TYPE_INTERNAL_URL,
  SVG_COLOR,
} from './../constants/global-constants'

export default function Navigation({ isIndexPage = false, sectionObserved }) {
  const [navbarOpen, setNavbarIsOpen] = useState(false)

  const data = useStaticQuery(graphql`
    {
      prismicSiteMeta {
        data {
          mobile_navigation_logo {
            alt
            url
          }
        }
      }
      prismicAboutPage {
        data {
          page_heading {
            text
          }
        }
      }
      prismicTestimonialsPage {
        data {
          page_heading {
            text
          }
        }
      }
      prismicContactDetailsPage {
        data {
          page_heading {
            text
          }
          email_address {
            text
          }
          telephone_number {
            text
          }
        }
      }
      prismicBookAConsultationPage {
        data {
          page_heading {
            text
          }
        }
      }
    }
  `)

  const prismicData = {
    mobileNavigationLogoAlt: data.prismicSiteMeta.data.mobile_navigation_logo.alt,
    mobileNavigationLogoUrl: data.prismicSiteMeta.data.mobile_navigation_logo.url,
    aboutPageHeading: data.prismicAboutPage.data.page_heading.text,
    testimonialsPageHeading: data.prismicTestimonialsPage.data.page_heading.text,
    contactDetailsPageHeading: data.prismicContactDetailsPage.data.page_heading.text,
    bookAConsultationPageHeading: data.prismicBookAConsultationPage.data.page_heading.text,
    emailAddress: data.prismicContactDetailsPage.data.email_address.text,
    telephoneNumber: data.prismicContactDetailsPage.data.telephone_number.text,
  }

  // console.log('Navigation prismicData:', prismicData)

  return (
    <StyledComponent>
      <NavMenuList
        isMobileMenu={false}
        isIndexPage={isIndexPage}
        clickHandler={setNavbarIsOpen}
        sectionObserved={sectionObserved}
      />
      <Hamburger className="hamburger" navbarOpen={navbarOpen} clickHandler={setNavbarIsOpen} />
      {navbarOpen && <RemoveScrollBar />}
      <div className={`menu ${navbarOpen ? 'menu--expanded' : ''}`}>
        <AnimateHeight className="animate-height" duration={SLIDE_DOWN_MOBILE_NAV_DURATION_MS} height={navbarOpen ? 'auto' : 0}>
          {prismicData.mobileNavigationLogoUrl && (
            <div className="mobile-menu-logo-wrapper">
              <Link to="/">
                <img
                  className="mobile-menu-logo"
                  src={prismicData.mobileNavigationLogoUrl}
                  alt={prismicData.mobileNavigationLogoAlt}
                  width="90"
                  height="90"
                />
              </Link>
            </div>
          )}
          <Container className="menu-container">
            <NavMenuList
              isMobileMenu={true}
              isIndexPage={isIndexPage}
              clickHandler={setNavbarIsOpen}
              sectionObserved={sectionObserved}
            />
          </Container>
        </AnimateHeight>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.nav`
  .hamburger {
    position: fixed;
    top: 2.3rem;
    right: 1rem;
    z-index: 4;

    @media (min-width: 375px) {
      top: 2.6rem;
    }

    @media (min-width: 1200px) {
      display: none;
    }
  }

  /* .animate-height {
    @media (min-width: 1200px) {
      height: auto !important;
      overflow: visible !important;
    }

    > div {
      @media (min-width: 1200px) {
        display: block !important;
      }
    }
  } */

  .mobile-menu-heading {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    font-weight: 300;
    text-transform: uppercase;

    span {
      font-weight: 700;
    }
  }

  .menu-container {
    padding: 0;
    overflow-y: auto;
    background-color: #fff;
    height: calc(100vh - 18rem);

    @media (min-height: 690px) {
      height: calc(100vh - 21rem);
    }

    @media (min-width: 1200px) {
      height: auto;
      background-color: transparent;
      overflow-y: visible;
    }
  }

  .menu {
    bottom: 0;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 3;

    &--expanded {
      pointer-events: all;
    }

    @media (min-width: 1200px) {
      position: static;
      pointer-events: all;
    }

    h1 {
      background-color: white;
      justify-content: center;
      letter-spacing: 0.3rem;
      margin: 0;
      padding: 7rem 0 0 0;
      text-align: center;

      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  .mobile-menu-logo-wrapper {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    padding-top: 8rem;
    padding-bottom: 1rem;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  .mobile-menu-logo {
    width: 9rem;
    height: auto;

    @media (min-height: 690px) {
      width: 12rem;
    }
  }

  .mobile-navigation-footer-text {
    border-top: 1px solid black;
    display: none;
    font-size: 1.3rem;
    margin: 0 auto;
    padding: 1rem 0;
    text-align: center;
    width: 13rem;

    @media (min-height: 667px) {
      display: block;
      margin-top: 3rem;
    }

    @media (min-height: 715px) {
      margin-top: 5rem;
    }

    @media (min-width: 1200px) {
      display: none;
    }
  }
`
