import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

import './../styles/styles.scss'

export default function SiteHeadMeta({ documentTitlePrefix = '' }) {
  const data = useStaticQuery(graphql`
    {
      prismicSiteMeta {
        data {
          site_title {
            text
          }
          site_description {
            text
          }
          google_site_verification_id {
            text
          }
          site_url {
            text
          }
          facebook_opengraph_image {
            url
          }
          twitter_sharing_card_image {
            url
          }
        }
      }
    }
  `)

  const prismicData = {
    siteTitle: data.prismicSiteMeta.data.site_title.text,
    siteDescription: data.prismicSiteMeta.data.site_description.text,
    googleSiteVerificationId: data.prismicSiteMeta.data.google_site_verification_id.text,
    siteUrl: data.prismicSiteMeta.data.site_url.text,
    facebookOpengraphImageUrl: data.prismicSiteMeta.data.facebook_opengraph_image.url,
    twitterSharingCardImageUrl: data.prismicSiteMeta.data.twitter_sharing_card_image.url,
  }

  const googleSiteVerificationId = prismicData.googleSiteVerificationId ? prismicData.googleSiteVerificationId : ''

  let documentTitle = prismicData.siteTitle
  if (documentTitlePrefix) {
    documentTitle = `${documentTitlePrefix} | ${documentTitle}`
  }

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{documentTitle}</title>
      <meta name="title" content={prismicData.siteTitle}></meta>
      <meta name="description" content={prismicData.siteDescription} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      {/* Google Site Verification ID */}
      <meta name="google-site-verification" content={googleSiteVerificationId} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={prismicData.siteUrl} />
      <meta property="og:title" content={prismicData.siteTitle} />
      <meta property="og:description" content={prismicData.siteDescription} />
      <meta property="og:image" content={prismicData.facebookOpengraphImageUrl} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={prismicData.siteUrl} />
      <meta property="twitter:title" content={prismicData.siteTitle} />
      <meta property="twitter:description" content={prismicData.siteDescription} />
      <meta property="twitter:image" content={prismicData.twitterSharingCardImageUrl} />

      {/* Google Fonts  */}
      {/* https://fonts.google.com/specimen/Montserrat?query=Montserrat */}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,600;0,700;1,400&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}
