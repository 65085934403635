import React, { useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import { RemoveScrollBar } from 'react-remove-scroll-bar'
import kebabCase from 'lodash/kebabCase'
import Hamburger from './hamburger'
import MenuItem from './menu-item'
import Container from './container'
import {
  SLIDE_DOWN_MOBILE_NAV_DURATION_MS,
  LINK_TYPE_DOWNLOAD_FILE,
  LINK_TYPE_EXTERNAL_URL,
  LINK_TYPE_SCROLL_TO_ANCHOR,
  LINK_TYPE_EMAIL_LINK,
  LINK_TYPE_TELEPHONE_LINK,
  LINK_TYPE_INTERNAL_URL,
  SVG_COLOR,
} from './../constants/global-constants'

export default function NavMenuList({ isMobileMenu = false, isIndexPage = false, clickHandler, sectionObserved }) {
  const data = useStaticQuery(graphql`
    {
      prismicSiteMeta {
        data {
          mobile_navigation_logo {
            alt
            url
          }
        }
      }
      prismicAboutPage {
        data {
          page_heading {
            text
          }
        }
      }
      prismicProceduresConditionsPage {
        data {
          page_heading {
            text
          }
        }
      }
      prismicTestimonialsPage {
        data {
          page_heading {
            text
          }
        }
      }
      prismicContactDetailsPage {
        data {
          page_heading {
            text
          }
          email_address {
            text
          }
          telephone_number {
            text
          }
        }
      }
      prismicBookAConsultationPage {
        data {
          page_heading {
            text
          }
        }
      }
    }
  `)

  const prismicData = {
    mobileNavigationLogoAlt: data.prismicSiteMeta.data.mobile_navigation_logo.alt,
    mobileNavigationLogoUrl: data.prismicSiteMeta.data.mobile_navigation_logo.url,
    aboutPageHeading: data.prismicAboutPage.data.page_heading.text,
    proceduresAndConditionsPageHeading: data.prismicProceduresConditionsPage.data.page_heading.text,
    testimonialsPageHeading: data.prismicTestimonialsPage.data.page_heading.text,
    contactDetailsPageHeading: data.prismicContactDetailsPage.data.page_heading.text,
    bookAConsultationPageHeading: data.prismicBookAConsultationPage.data.page_heading.text,
    emailAddress: data.prismicContactDetailsPage.data.email_address.text,
    telephoneNumber: data.prismicContactDetailsPage.data.telephone_number.text,
  }

  // console.log('Navigation prismicData:', prismicData)

  return (
    <StyledComponent className={`nav-menu-list nav-menu-list--${isMobileMenu ? 'mobile' : 'desktop'}`}>
      <MenuItem
        label="Home"
        linkTo={isIndexPage ? 'top' : '/'}
        isSubMenuItem={false}
        className={`home ${sectionObserved === 'hero-carousel' ? 'active' : null}`}
        linkToClickHandler={clickHandler}
        linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
        showLabelText={true}
        doHoverUnderline={true}
      />
      <MenuItem
        label={prismicData.aboutPageHeading}
        linkTo={isIndexPage ? kebabCase(prismicData.aboutPageHeading) : `/#${kebabCase(prismicData.aboutPageHeading)}`}
        isSubMenuItem={false}
        className={`about ${sectionObserved === 'about' ? 'active' : null}`}
        linkToClickHandler={clickHandler}
        linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
        showLabelText={true}
        doHoverUnderline={true}
      />
      <MenuItem
        label={prismicData.proceduresAndConditionsPageHeading}
        linkTo={
          isIndexPage
            ? kebabCase(prismicData.proceduresAndConditionsPageHeading)
            : `/#${kebabCase(prismicData.proceduresAndConditionsPageHeading)}`
        }
        isSubMenuItem={false}
        className={`procedures-conditions ${sectionObserved === 'procedures-conditions' ? 'active' : null}`}
        linkToClickHandler={clickHandler}
        linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
        showLabelText={true}
        doHoverUnderline={true}
      />
      <MenuItem
        label={prismicData.testimonialsPageHeading}
        linkTo={
          isIndexPage ? kebabCase(prismicData.testimonialsPageHeading) : `/#${kebabCase(prismicData.testimonialsPageHeading)}`
        }
        isSubMenuItem={false}
        className={`testimonials ${sectionObserved === 'testimonials' ? 'active' : null}`}
        linkToClickHandler={clickHandler}
        linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
        showLabelText={true}
        doHoverUnderline={true}
      />
      <MenuItem
        label={prismicData.contactDetailsPageHeading}
        linkTo={
          isIndexPage ? kebabCase(prismicData.contactDetailsPageHeading) : `/#${kebabCase(prismicData.contactDetailsPageHeading)}`
        }
        isSubMenuItem={false}
        className={`contact-details ${sectionObserved === 'contact-details' ? 'active' : null}`}
        linkToClickHandler={clickHandler}
        linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
        showLabelText={true}
        doHoverUnderline={true}
      />
      <MenuItem
        label={prismicData.bookAConsultationPageHeading}
        ariaLabel={prismicData.bookAConsultationPageHeading}
        linkTo={
          isIndexPage
            ? kebabCase(prismicData.bookAConsultationPageHeading)
            : `/#${kebabCase(prismicData.bookAConsultationPageHeading)}`
        }
        isSubMenuItem={false}
        className={`book-a-consultation ${sectionObserved === 'book-a-consultation' ? 'active' : null}`}
        linkToClickHandler={clickHandler}
        linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
        showLabelText={true}
        doHoverUnderline={true}
      />
      <MenuItem
        label="Call"
        ariaLabel="Call Us"
        linkTo={prismicData.telephoneNumber}
        isSubMenuItem={false}
        className="telephone"
        linkToClickHandler={clickHandler}
        linkType={LINK_TYPE_TELEPHONE_LINK}
        showLabelText={false}
        doHoverUnderline={false}
        svgMarkup={`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500.2" fill="${SVG_COLOR}"><path d="m128 199 16-13 45-37c5-5 8-12 6-20l-2-7-46-107c-2-4-4-8-8-11-5-4-10-5-16-3L19 25 8 29c-5 4-8 9-8 16a274 274 0 0 0 3 52 423 423 0 0 0 102 241 512 512 0 0 0 130 107 422 422 0 0 0 230 53c5-3 8-8 10-13l9-39 15-66c1-3 2-7 1-11-1-5-4-9-9-12l-14-7-100-43c-3-1-7-3-11-3-6 1-11 3-15 7l-13 16-36 44-2 1a352 352 0 0 1-172-173z"/></svg>`}
      />
      <MenuItem
        label="Email"
        ariaLabel="Email Us"
        linkTo={prismicData.emailAddress}
        isSubMenuItem={false}
        className="email"
        linkToClickHandler={clickHandler}
        linkType={LINK_TYPE_EMAIL_LINK}
        showLabelText={false}
        doHoverUnderline={false}
        svgMarkup={`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 439.371 331.469" stroke="${SVG_COLOR}" fill="none"><path d="M569.374 461.472V160.658H160.658v300.814h408.716z" style="stroke-width:30.65500069" transform="translate(-145.33 -145.33)"/><path d="m164.46 164.49 176.32 178.668c13.069 13.17 36.85 13.014 49.643.12l176.199-177.35M170.515 451.566 305.61 313.46M557.968 449.974 426.515 315.375" style="stroke-width:30.65500069" transform="translate(-145.33 -145.33)"/></svg>`}
      />
    </StyledComponent>
  )
}

const StyledComponent = styled.ul`
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  pointer-events: all;
  padding: 2rem 0 0 0;

  @media (min-width: 1200px) {
    align-items: center;
    background-color: transparent;
    flex-direction: row;
    height: auto;
    padding: 0;
    overflow-y: visible;
  }

  &.nav-menu-list--mobile {
    display: block;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  &.nav-menu-list--desktop {
    display: none;

    @media (min-width: 1200px) {
      display: flex;
    }
  }

  .menu-item-link {
    svg {
      transition: fill 0.4s linear;
    }

    &.menu-item-link {
      &--telephone {
        display: flex;
        flex-direction: column;
        height: 4rem;
        justify-content: center;
        margin-top: 2rem;
        padding: 0;
        width: 3.5rem;

        @media (min-width: 1200px) {
          margin-top: 0;
        }

        svg {
          transition: fill 0.4s linear;
          fill: #262626;

          @media (min-width: 1200px) {
            fill: #adadad;
          }
        }

        &:hover {
          svg {
            fill: #262626;
          }
        }
      }
      &--email {
        display: flex;
        flex-direction: column;
        height: 4rem;
        justify-content: center;
        margin-top: 2rem;
        padding: 0;
        width: 3.5rem;

        @media (min-width: 1200px) {
          margin-top: 0;
        }

        svg {
          path {
            transition: stroke 0.4s linear;
            stroke: #262626;

            @media (min-width: 1200px) {
              stroke: #adadad;
            }
          }
        }

        &:hover {
          svg {
            path {
              stroke: #262626;
            }
          }
        }
      }
    }
  }
`
