export const PAGE_ROOT_ELEMENT_ID = '___gatsby'
export const SITE_HEADER_ELEMENT_ID = 'site-header'

export const HOMEPAGE_INTRO_CSS_MODIFIER = 'homepage-intro'
export const ABOUT_SECTION_CSS_MODIFIER = 'about'
// export const PROCEDURES_SECTION_CSS_MODIFIER = 'procedures'
// export const CONDITIONS_SECTION_CSS_MODIFIER = 'conditions'
export const PROCEDURES_AND_CONDITIONS_SECTION_CSS_MODIFIER = 'procedures-conditions'
export const TESTIMONIALS_SECTION_CSS_MODIFIER = 'testimonials'
export const CONTACT_DETAILS_SECTION_CSS_MODIFIER = 'contact-details'
export const BOOK_A_CONSULTATION_SECTION_CSS_MODIFIER = 'book-a-consultation'

export const NAV_MENU_DESKTOP_BREAKPOINT = 1200
export const SLIDE_DOWN_MOBILE_NAV_DURATION_MS = 600
export const SLIDE_DOWN_SUB_NAV_DURATION_MS = 600
export const HOME_PAGE_SCROLL_DOWN_DURATION_MS = 1000
export const SCROLL_TO_TOP_DURATION_MS = 1000

export const LINK_TYPE_DOWNLOAD_FILE = 'LINK_TYPE_DOWNLOAD_FILE'
export const LINK_TYPE_EXTERNAL_URL = 'LINK_TYPE_EXTERNAL_URL'
export const LINK_TYPE_SCROLL_TO_ANCHOR = 'LINK_TYPE_SCROLL_TO_ANCHOR'
export const LINK_TYPE_EMAIL_LINK = 'LINK_TYPE_EMAIL_LINK'
export const LINK_TYPE_TELEPHONE_LINK = 'LINK_TYPE_TELEPHONE_LINK'
export const LINK_TYPE_INTERNAL_URL = 'LINK_TYPE_INTERNAL_URL'
export const LINK_TYPE_SUB_NAV_HEADING = 'LINK_TYPE_SUB_NAV_HEADING'

export const SCROLL_TO_TOP_BUTTON_TEXT = 'Back to top'

export const GOOGLE_MAPS_LINK_TEXT = 'CLICK FOR GOOGLE MAPS.'

export const SVG_COLOR = '#ADADAD'
