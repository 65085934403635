import React from 'react'
import styled from 'styled-components'

export default function Container({children, narrow, doublePadding, id = '', className = '', bgColor = 'transparent'}) {
  return (
    <StyledComponent id={id} className={`container ${className}`} narrow={narrow} doublePadding={doublePadding} bgColor={bgColor}>
      {children}
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  background-color: ${props => props.bgColor};
  margin: 0 auto;
  max-width: ${props => (props.narrow ? '90rem' : '147rem')};
  padding: 0 2rem;
  width: 100%;

  @media (min-width: 600px) {
    padding: 0 2rem;
  }

  @media (min-width: 1200px) {
    padding: ${props => (props.doublePadding ? '0 5rem 0 11rem' : '0 7.6rem')};
  }
`
