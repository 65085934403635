import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Fade } from 'react-awesome-reveal'
import kebabCase from 'lodash/kebabCase'
import Container from './container'
import { removeWhitespace } from './../helper-functions/remove-whitespace'
import MenuItem from './menu-item'
import {
  LINK_TYPE_DOWNLOAD_FILE,
  LINK_TYPE_EXTERNAL_URL,
  LINK_TYPE_SCROLL_TO_ANCHOR,
  LINK_TYPE_EMAIL_LINK,
  LINK_TYPE_INTERNAL_URL,
} from './../constants/global-constants'

const FOOTER_DESKTOP_LAYOUT_BREAKPOINT = '900px'
const FOOTER_IN_SINGLE_ROW_BREAKPOINT = '1320px'

export default function Footer({ pageName, isIndexPage = false }) {
  const data = useStaticQuery(graphql`
    {
      prismicFooter {
        data {
          copyright_text {
            text
          }
          email_link_text {
            text
          }
          footer_logo {
            alt
            url
          }
          phone_text {
            html
          }
          quick_links_heading {
            text
          }
        }
      }
      prismicAboutPage {
        data {
          page_heading {
            text
          }
        }
      }
      prismicProceduresConditionsPage {
        data {
          page_heading {
            text
          }
        }
      }
      prismicTestimonialsPage {
        data {
          page_heading {
            text
          }
        }
      }
      prismicContactDetailsPage {
        data {
          page_heading {
            text
          }
          email_address {
            text
          }
          telephone_number {
            text
          }
        }
      }
      prismicBookAConsultationPage {
        data {
          page_heading {
            text
          }
        }
      }
      prismicPrivacyPolicyPage {
        data {
          page_heading {
            text
          }
        }
      }
    }
  `)

  const prismicData = {
    copyrightText: data.prismicFooter.data.copyright_text.text,
    emailLinkText: data.prismicFooter.data.email_link_text.text,
    footerLogoUrl: data.prismicFooter.data.footer_logo.url,
    footerLogoAlt: data.prismicFooter.data.footer_logo.alt,
    phoneTextHtml: data.prismicFooter.data.phone_text.html,
    quickLinksHeadingText: data.prismicFooter.data.quick_links_heading.text,
    emailAddress: data.prismicContactDetailsPage.data.email_address.text,
    telephoneNumber: data.prismicContactDetailsPage.data.telephone_number.text,
    aboutPageHeading: data.prismicAboutPage.data.page_heading.text,
    proceduresAndConditionsPageHeading: data.prismicProceduresConditionsPage.data.page_heading.text,
    testimonialsPageHeading: data.prismicTestimonialsPage.data.page_heading.text,
    contactDetailsPageHeading: data.prismicContactDetailsPage.data.page_heading.text,
    bookAConsultationPageHeading: data.prismicBookAConsultationPage.data.page_heading.text,
    privacyPolicyPageHeading: data.prismicPrivacyPolicyPage.data.page_heading.text,
  }

  // console.log('Footer prismicData:', prismicData)

  const date = new Date()

  return (
    <StyledComponent className={`site-footer site-footer--${pageName}`}>
      <Container type="wide" className="container--footer">
        <div className={`footer-inner footer-inner--1`}>
          <Fade>
            <img
              className="footer-logo"
              src={prismicData.footerLogoUrl}
              alt={prismicData.footerLogoAlt}
              width="100"
              height="100"
            />
          </Fade>
        </div>
        <div className={`footer-inner footer-inner--2`}>
          <nav className="footer-nav">
            <Fade>
              <div>
                <h3 className="quick-links-heading">{prismicData.quickLinksHeadingText}</h3>
                <ul className="menu-list--footer">
                  <MenuItem
                    label="Home"
                    linkTo={isIndexPage ? 'top' : '/'}
                    isSubMenuItem={false}
                    className="home"
                    isFooterNav={true}
                    linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
                    doHoverUnderline={false}
                  />
                  <MenuItem
                    label={prismicData.aboutPageHeading}
                    linkTo={
                      isIndexPage ? kebabCase(prismicData.aboutPageHeading) : `/#${kebabCase(prismicData.aboutPageHeading)}`
                    }
                    isSubMenuItem={false}
                    className="about"
                    isFooterNav={true}
                    linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
                    doHoverUnderline={false}
                  />
                  <MenuItem
                    label={prismicData.proceduresAndConditionsPageHeading}
                    linkTo={
                      isIndexPage
                        ? kebabCase(prismicData.proceduresAndConditionsPageHeading)
                        : `/#${kebabCase(prismicData.proceduresAndConditionsPageHeading)}`
                    }
                    isSubMenuItem={false}
                    className="procedures-conditions"
                    isFooterNav={true}
                    linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
                    doHoverUnderline={false}
                  />
                  <MenuItem
                    label={prismicData.testimonialsPageHeading}
                    linkTo={
                      isIndexPage
                        ? kebabCase(prismicData.testimonialsPageHeading)
                        : `/#${kebabCase(prismicData.testimonialsPageHeading)}`
                    }
                    isSubMenuItem={false}
                    className="testimonials"
                    isFooterNav={true}
                    linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
                    doHoverUnderline={false}
                  />
                  <MenuItem
                    label={prismicData.contactDetailsPageHeading}
                    linkTo={
                      isIndexPage
                        ? kebabCase(prismicData.contactDetailsPageHeading)
                        : `/#${kebabCase(prismicData.contactDetailsPageHeading)}`
                    }
                    isSubMenuItem={false}
                    className="contact-details"
                    isFooterNav={true}
                    linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
                    doHoverUnderline={false}
                  />
                  <MenuItem
                    label={prismicData.bookAConsultationPageHeading}
                    linkTo={
                      isIndexPage
                        ? kebabCase(prismicData.bookAConsultationPageHeading)
                        : `/#${kebabCase(prismicData.bookAConsultationPageHeading)}`
                    }
                    isSubMenuItem={false}
                    className="book-a-consultation"
                    isFooterNav={true}
                    linkType={isIndexPage ? LINK_TYPE_SCROLL_TO_ANCHOR : LINK_TYPE_INTERNAL_URL}
                    doHoverUnderline={false}
                  />
                  <MenuItem
                    label={prismicData.privacyPolicyPageHeading}
                    linkTo="/privacy-policy"
                    isSubMenuItem={false}
                    className="privacy-policy"
                    isFooterNav={true}
                    linkType={LINK_TYPE_INTERNAL_URL}
                    doHoverUnderline={false}
                  />
                </ul>
              </div>
            </Fade>
          </nav>
        </div>
        <div className={`footer-inner footer-inner--3`}>
          <Fade>
            <a className="email-link" href={`mailto:${prismicData.emailAddress}`} target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 439.371 331.469"
                stroke="white"
                fill="none"
                style={{ strokeWidth: 30 }}
              >
                <path d="M569.374 461.472V160.658H160.658v300.814h408.716z" transform="translate(-145.33 -145.33)" />
                <path
                  d="m164.46 164.49 176.32 178.668c13.069 13.17 36.85 13.014 49.643.12l176.199-177.35M170.515 451.566 305.61 313.46M557.968 449.974 426.515 315.375"
                  transform="translate(-145.33 -145.33)"
                />
              </svg>
              <span>{prismicData.emailLinkText}</span>
            </a>
          </Fade>
        </div>
        <div className={`footer-inner footer-inner--4`}>
          <Fade>
            <a className="telephone-link" href={`tel:${prismicData.telephoneNumber}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500.2" fill="white">
                <path d="m128 199 16-13 45-37c5-5 8-12 6-20l-2-7-46-107c-2-4-4-8-8-11-5-4-10-5-16-3L19 25 8 29c-5 4-8 9-8 16a274 274 0 0 0 3 52 423 423 0 0 0 102 241 512 512 0 0 0 130 107 422 422 0 0 0 230 53c5-3 8-8 10-13l9-39 15-66c1-3 2-7 1-11-1-5-4-9-9-12l-14-7-100-43c-3-1-7-3-11-3-6 1-11 3-15 7l-13 16-36 44-2 1a352 352 0 0 1-172-173z" />
              </svg>
              <div dangerouslySetInnerHTML={{ __html: prismicData.phoneTextHtml }} />
            </a>
          </Fade>
        </div>
        <div className={`footer-inner footer-inner--5`}>
          <Fade>
            <p className="copyright-text">{prismicData.copyrightText}</p>
          </Fade>
        </div>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.footer`
  background-color: #7f7f7f;
  color: #fff;
  font-size: 1.4rem;
  padding: 4rem 0;

  @media (min-width: 1200px) {
    padding: 2rem 0;
  }

  .container--footer {
    @media (min-width: 1000px) {
      display: grid;
      grid-template-columns: 0.6fr 1fr 0.6fr 1.1fr;
      gap: 0px 0px;
      grid-template-areas:
        'logo links email telephone'
        'copyright copyright copyright copyright';
    }

    @media (min-width: 1200px) {
      align-items: flex-start;
      grid-template-columns: 0.5fr 1fr 0.6fr 1.2fr 0.5fr;
      grid-template-areas: 'logo links email telephone copyright';
      padding: 0 2rem;
    }

    @media (min-width: 1300px) {
      grid-template-columns: 0.5fr 1fr 0.6fr 1.1fr 0.5fr;
    }

    @media (min-width: 1400px) {
      grid-template-columns: 0.4fr 1fr 0.6fr 1.1fr 0.4fr;
    }
  }

  .footer-inner {
    margin: 0 0 4rem 0;

    @media (min-width: 1200px) {
      margin: 0;
    }

    &--1 {
      grid-area: logo;
    }

    &--2 {
      grid-area: links;
    }

    &--3 {
      grid-area: email;

      @media (min-width: 1200px) {
        padding-top: 1.4rem !important;
      }
    }

    &--4 {
      grid-area: telephone;
    }

    &--5 {
      grid-area: copyright;

      @media (min-width: 1200px) {
        padding-top: 2.2rem !important;
      }
    }

    &--3,
    &--4,
    &--5 {
      @media (min-width: 1200px) {
        position: relative;
        padding: 0 0 0 2.5rem;

        &::before {
          background-color: white;
          content: '';
          display: block;
          height: 6rem;
          left: 0;
          position: absolute;
          top: 0;
          /* transform: translateY(-50%); */
          width: 1px;
        }
      }
    }
  }

  .footer-logo {
    margin: 0 auto;
    max-width: 15rem;

    @media (min-width: 1000px) {
      margin: 0;
      max-width: 10rem;
    }

    @media (min-width: 1200px) {
      margin: 0;
    }
  }

  .footer-nav {
  }
  .quick-links-heading {
    font-size: 1.7rem;
    margin: 0 0 1rem 0;
    color: white;
    text-align: center;

    @media (min-width: 1000px) {
      text-align: left;
      margin: 0 0 1rem 0;
      max-width: 19rem;
      padding: 0 0 0 1rem;
    }

    @media (min-width: 1200px) {
      font-size: 1.4rem;
      margin: 0 0 0.5rem 0;
    }
  }

  .menu-list--footer {
    margin: 0;
    padding: 0;

    @media (min-width: 1400px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    li.menu-item {
      margin-bottom: 1rem;
      margin-right: 0;

      @media (min-width: 1000px) {
        align-items: flex-start;
        max-width: 19rem;
        margin: 0 0 0.5rem 0;
      }

      /* @media (min-width: 1300px) {
        max-width: none;
        min-width: 18rem;
        margin: 0;
      } */

      .menu-item-link {
        transition: none;

        &--footer {
          @media (min-width: 1200px) {
            padding: 0.3rem 1rem;
            font-size: 1.3rem;
            line-height: 1;
          }
        }

        &:hover {
          color: black;
        }
      }
    }
  }

  .email-link {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    justify-content: center;
    line-height: 1.2;

    @media (min-width: 1000px) {
      flex-direction: row;
      justify-content: flex-start;
    }

    @media (min-width: 1200px) {
      justify-content: flex-start;
      position: relative;
    }

    &:hover {
      color: black;

      svg {
        path {
          stroke: black;
        }
      }
    }

    svg {
      width: 3rem;
      margin-bottom: 0.5rem;

      @media (min-width: 1000px) {
        margin-right: 1rem;
        margin-bottom: 0;
      }

      @media (min-width: 1200px) {
        width: 4rem;
      }
    }
  }

  .telephone-link {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    justify-content: center;
    line-height: 1.2;
    text-align: center;

    @media (min-width: 1000px) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
    }

    @media (min-width: 1200px) {
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }

    &:hover {
      color: black;

      svg {
        fill: black;
      }
    }

    svg {
      width: 3rem;
      margin-bottom: 0.5rem;

      @media (min-width: 1000px) {
        margin-right: 1rem;
        margin-bottom: 0;
      }

      @media (min-width: 1200px) {
        width: 3rem;
      }
    }
  }

  .copyright-text {
    text-align: center;

    @media (min-width: 1200px) {
      text-align: left;
      font-size: 1.3rem;
      line-height: 1.2;
      position: relative;
    }
  }
`
