import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import useOutsideClick from './../hooks/useOutsideClick'
import {
  NAV_MENU_DESKTOP_BREAKPOINT,
  SLIDE_DOWN_SUB_NAV_DURATION_MS,
  HOME_PAGE_SCROLL_DOWN_DURATION_MS,
  LINK_TYPE_DOWNLOAD_FILE,
  LINK_TYPE_EXTERNAL_URL,
  LINK_TYPE_SCROLL_TO_ANCHOR,
  LINK_TYPE_EMAIL_LINK,
  LINK_TYPE_TELEPHONE_LINK,
  LINK_TYPE_INTERNAL_URL,
  LINK_TYPE_SUB_NAV_HEADING,
} from './../constants/global-constants'

export default function MenuItem({
  ariaLabel,
  className,
  clickHandler,
  clickOutsideHandler,
  doHoverUnderline = true,
  isExpanded,
  isFooterNav = false,
  isSubMenuItem = false,
  label,
  linkTo,
  linkToClickHandler,
  linkType,
  showLabelText = true,
  subMenuConstant,
  subMenuItems,
  svgMarkup,
}) {
  const menuItemRef = useRef()

  useOutsideClick(menuItemRef, () => {
    const isBrowser = typeof window !== `undefined`
    if (isBrowser) {
      const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width
      if (clickOutsideHandler && width >= NAV_MENU_DESKTOP_BREAKPOINT) {
        clickOutsideHandler(subMenuConstant)
      }
    }
  })

  function onMenuItemClick() {
    if (linkToClickHandler) {
      linkToClickHandler(false)
    }
  }

  return (
    <StyledComponent
      className={`menu-item ${className ? `menu-item--${className}` : null} ${isFooterNav ? 'menu-item--footer footer' : ''}`}
      ref={menuItemRef}
      isFooterNav={isFooterNav}
    >
      {linkType === LINK_TYPE_DOWNLOAD_FILE && (
        <a
          className={`menu-item-link menu-item-link--download-file ${doHoverUnderline ? 'do-hover-underline' : null} ${
            className ? `menu-item-link--${className}` : null
          } ${isFooterNav ? 'menu-item-link--footer footer' : ''}`}
          href={linkTo}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <span>{label}</span>
        </a>
      )}
      {linkType === LINK_TYPE_INTERNAL_URL && (
        <Link
          to={linkTo}
          className={`menu-item-link menu-item-link--scroll-to-anchor ${doHoverUnderline ? 'do-hover-underline' : null} ${
            className ? `menu-item-link--${className}` : null
          } ${isFooterNav ? 'menu-item-link--footer footer' : ''}`}
          dangerouslySetInnerHTML={{ __html: `<span class="${showLabelText ? null : 'sr-only'}">${label}</span>` }}
          onClick={onMenuItemClick}
          aria-label={ariaLabel}
        />
      )}
      {linkType === LINK_TYPE_EXTERNAL_URL && (
        <a
          className={`menu-item-link menu-item-link--external-url ${doHoverUnderline ? 'do-hover-underline' : null} ${
            className ? `menu-item-link--${className}` : null
          } ${isFooterNav ? 'menu-item-link--footer footer' : ''}`}
          href={linkTo}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={showLabelText ? null : 'sr-only'} dangerouslySetInnerHTML={{ __html: label }} />
        </a>
      )}
      {linkType === LINK_TYPE_EMAIL_LINK && (
        <a
          className={`menu-item-link menu-item-link--email-link ${doHoverUnderline ? 'do-hover-underline' : null} ${
            className ? `menu-item-link--${className}` : null
          } ${isFooterNav ? 'menu-item-link--footer footer' : ''}`}
          href={`mailto:${linkTo.replace(/\s/g, '')}`}
          aria-label={ariaLabel}
          target="_blank"
          rel="noopener noreferrer"
        >
          {svgMarkup && <span className="svg-wrapper" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgMarkup }} />}
          <span className={showLabelText ? null : 'sr-only'} dangerouslySetInnerHTML={{ __html: label }} />
        </a>
      )}
      {linkType === LINK_TYPE_TELEPHONE_LINK && (
        <a
          className={`menu-item-link menu-item-link--tel-link ${doHoverUnderline ? 'do-hover-underline' : null} ${
            className ? `menu-item-link--${className}` : null
          } ${isFooterNav ? 'menu-item-link--footer footer' : ''}`}
          href={`tel:${linkTo.replace(/\s/g, '')}`}
          aria-label={ariaLabel}
        >
          {svgMarkup && <span className="svg-wrapper" aria-hidden="true" dangerouslySetInnerHTML={{ __html: svgMarkup }} />}
          <span className={showLabelText ? null : 'sr-only'} dangerouslySetInnerHTML={{ __html: label }} />
        </a>
      )}
      {linkType === LINK_TYPE_SCROLL_TO_ANCHOR && (
        <ScrollLink
          href="#"
          to={linkTo}
          smooth={true}
          duration={HOME_PAGE_SCROLL_DOWN_DURATION_MS}
          className={`menu-item-link menu-item-link--scroll-to-anchor ${doHoverUnderline ? 'do-hover-underline' : null} ${
            className ? `menu-item-link--${className}` : null
          } ${isFooterNav ? 'menu-item-link--footer footer' : ''}`}
          dangerouslySetInnerHTML={{ __html: `<span class="${showLabelText ? null : 'sr-only'}">${label}</span>` }}
          onClick={onMenuItemClick}
          delay={isFooterNav ? 0 : 400}
        />
      )}
      {linkType === LINK_TYPE_SUB_NAV_HEADING && (
        <>
          <button
            type="button"
            className={`menu-item-link menu-item-link--sub-nav-heading ${doHoverUnderline ? 'do-hover-underline' : null} ${
              className ? `menu-item-link--${className}` : null
            } ${isFooterNav ? 'menu-item-link--footer footer' : ''}`}
            onClick={() => clickHandler(subMenuConstant)}
          >
            <span className={showLabelText ? null : 'sr-only'} dangerouslySetInnerHTML={{ __html: label }} />
          </button>
          <AnimateHeight
            className="sub-menu-list__animate-height"
            duration={SLIDE_DOWN_SUB_NAV_DURATION_MS}
            height={isExpanded ? 'auto' : 0}
          >
            <ul className={`sub-menu-list sub-menu-list--${className}`}>
              {subMenuItems.map((item, index) => (
                <MenuItem
                  key={`menu-item-${index}`}
                  label={item.label}
                  linkTo={item.linkTo}
                  isSubMenuItem={true}
                  className={item.className}
                  ariaLabel={item.ariaLabel}
                  showLabelText={item.showLabelText}
                  doHoverUnderline={item.doHoverUnderline}
                />
              ))}
            </ul>
          </AnimateHeight>
        </>
      )}
    </StyledComponent>
  )
}

const StyledComponent = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props => (props.isFooterNav ? '0' : '0')};

  @media (min-height: 667px) {
    margin-bottom: ${props => (props.isFooterNav ? '0' : '0')};
  }

  @media (min-height: 900px) {
    margin-bottom: ${props => (props.isFooterNav ? '0' : '1rem')};
  }

  &:last-of-type {
    margin-bottom: 2rem;
  }

  @media (min-width: 1200px) {
    margin: 0 1rem 0 0;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  @media (min-width: 1250px) {
    margin-right: 1.5rem;
  }

  @media (min-width: 1300px) {
    margin-right: 2.2rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (min-width: 1350px) {
    margin-right: 2.4rem;
  }

  @media (min-width: 1400px) {
    margin-right: 2.8rem;
  }

  &--footer {
    margin-bottom: 0;
  }

  .menu-item-link {
    background-color: transparent;
    border: 0;
    color: #000;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.35rem;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    padding: 1rem 0.5rem;
    position: relative;
    text-transform: uppercase;
    transition: color 0.5s linear;

    @media (min-height: 600px) {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    @media (min-height: 640px) {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }

    @media (min-height: 760px) {
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
    }

    @media (min-height: 800px) {
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }

    &.do-hover-underline {
      &::after {
        background-color: #909090;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%) scaleX(0);
        transform-origin: top center;
        transition: all 0.25s linear;
        width: 6rem;
      }

      &:hover {
        &::after {
          transform: translateX(-50%) scaleX(1);
        }
      }

      &.active {
        &::after {
          transform: translateX(-50%) scaleX(1);
        }
      }
    }

    @media (min-width: 1200px) {
      padding: 1.8rem 0.5rem;
    }

    &:hover {
      color: #000;
    }

    &--footer {
      color: white;
      font-weight: 300;
      padding: 0.5rem 1rem;
      text-transform: none;

      &:hover {
        color: white;
      }

      &::after {
        display: none;
      }
    }
  }

  .sub-menu-list__animate-height {
    @media (min-width: 1200px) {
      position: absolute;
      top: 100%;
      left: 0;
      padding: 0 0.3rem 0.3rem 0;
    }
  }

  .sub-menu-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 3rem 0 0 0;

    @media (min-width: 1200px) {
      align-items: flex-start;
      background-color: #fff;
      /* box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.1); */
      border: 1px solid #cacaca;
      border-top-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 2rem 1rem;
      position: relative;
      z-index: 1000;
    }

    .menu-item {
      margin-bottom: 3rem;

      @media (min-width: 1200px) {
        margin: 0 0 1.5rem 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: #6a6a6a;
      font-size: 1.6rem;
      transition: color 0.5s linear;

      &:hover {
        color: #000;
      }
    }
  }
`
