import React from 'react'
import styled from 'styled-components'
import {Fade} from 'react-awesome-reveal'
import {Link as ScrollLink} from 'react-scroll'
import {SCROLL_TO_TOP_BUTTON_TEXT, SCROLL_TO_TOP_DURATION_MS} from './../constants/global-constants'
import DownArrowSvg from './down-arrow-svg'

export default function ScrollToTopBtn() {
  return (
    <StyledComponent className="scroll-to-top-btn-wrapper">
      <Fade>
        <div>
          <ScrollLink
            href="#"
            to="top"
            smooth={true}
            duration={SCROLL_TO_TOP_DURATION_MS}
            className="scroll-to-top-btn"
            delay={0}
          >
            <DownArrowSvg className="up-arrow-svg" fill="#000" />
            {SCROLL_TO_TOP_BUTTON_TEXT}
          </ScrollLink>
        </div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  margin-top: 7rem;

  .up-arrow-svg {
    animation: scrollToTopArrowAnimation 3s linear 0s infinite normal none;
    display: block;
    height: 2.9rem;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 0.5rem;
    transform: translateX(-50%) scaleY(-1);
    width: 5rem;
  }
  .scroll-to-top-btn {
    border: 0;
    display: block;
    margin: 0 auto;
    padding-top: 4.5rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 14rem;
  }
`
