import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Fade } from 'react-awesome-reveal'

import Navigation from './navigation'
import Container from './container'
import { SITE_HEADER_ELEMENT_ID } from './../constants/global-constants'

export default function Header({ pageName, isIndexPage = false, sectionObserved }) {
  const data = useStaticQuery(graphql`
    {
      prismicSiteMeta {
        data {
          header_logo {
            alt
            url
          }
          site_title {
            text
          }
        }
      }
    }
  `)

  const prismicData = {
    siteTitle: data.prismicSiteMeta.data.site_title.text,
    headerLogoUrl: data.prismicSiteMeta.data.header_logo.url,
    headerLogoAlt: data.prismicSiteMeta.data.header_logo.alt,
  }

  // console.log('Header prismicData:', prismicData)

  return (
    <StyledComponent id={SITE_HEADER_ELEMENT_ID} className={`site-header site-header--${pageName}`}>
      <Fade delay={0}>
        <div className="header-inner">
          <Container className="header-container" type="wide">
            <Link to="/" className="header-heading-link">
              <img
                className="header-logo"
                src={prismicData.headerLogoUrl}
                alt={prismicData.headerLogoAlt}
                width="224"
                height="58"
              />
            </Link>
            <Navigation isIndexPage={isIndexPage} sectionObserved={sectionObserved} />
          </Container>
        </div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.header`
  background-color: #fff;
  height: 8.644rem;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;

  @media (min-width: 375px) {
    height: 9.95rem;
  }

  .header-container {
    position: relative;

    @media (min-width: 1200px) {
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 0 2rem;
      justify-content: space-between;
    }
  }
  .header-inner {
    padding-top: 2rem;
    padding-bottom: 1.6rem;

    @media (min-width: 1200px) {
      padding-bottom: 2rem;
    }
  }
  .header-heading-link {
    display: inline-block;
    /* margin-left: -2rem; */

    @media (min-width: 1200px) {
      /* margin-left: -5rem; */
    }
  }
  .header-logo {
    height: auto;
    max-width: 17.4rem;
    width: 100%;

    @media (min-width: 375px) {
      max-width: 22.4rem;
    }
  }
`
